@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

.main-layout {
  height: 100%;
}

.main-content {
  margin: 10px;
  height: calc(100% - 64px);
}
