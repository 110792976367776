.files-list .files-list-item {
    position: relative;
}
.files-list .files-list-item .filename {
    position: absolute;
    width: 100%;
    background-color: #00000030;
    z-index: 20;
    padding-left: 10px;
}
.files-list .files-list-item .footer-with-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #00000030;
    z-index: 20;
    padding-left: 10px;
    display: inline-block;
}
.files-list .files-list-item .footer-with-actions .creator {
    padding-right: 36px;
}
.files-list .files-list-item .footer-with-actions .actions {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
}
.files-list .files-list-item .footer-with-actions .actions .ant-btn-icon-only {
    width: 16px;
    height: 16px;
}
.files-list .files-list-item .ant-card {
    min-height: 246px;
    display: flex;
    justify-content: center;
    border-width: 3px;
}
.files-list .files-list-item .ant-card-body {
    padding: 20px;
}
.files-list .files-list-item .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}
.files-list-item-image {
    max-width: 200px;
    max-height: 200px;
}

.preview-pdf {
    position: absolute;
    z-index: 20;
    top: 12px;
    right: -6px;
    display: none;
}

.files-list .files-list-item .ant-card:hover .preview-pdf {
    display: block;
}
.files-list .files-list-item.new-item .ant-card{
    border-color: #1890ff;
}
